import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useAdminState } from "../../context/AdminContext";
import Loading from "../../shared/Loading";
import getProductionList from "../../services/getProductionList";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Container,
  Fab,
  Paper,
  Select,
  FormControl,
  InputLabel,
  Button,
  Dialog,
  MenuItem,
} from "@mui/material";
import { navigate } from "gatsby";
import AddEditProject from "../AddEditProject";
import PROJECT_STATUS from "../../constants/projectStatus";
import moment from "moment";
import { mkConfig, generateCsv, download } from "export-to-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Breadcrumb from "../../shared/Breadcrumbs";
import {
  CheckBadgeIcon,
  NoSymbolIcon,
  ShieldExclamationIcon,
  Cog8ToothIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import NoData from "../../shared/NoData";

const useStyles = makeStyles((theme) => ({
  svg: {
    position: "relative",
    top: 6,
    marginRight: 5,
    width: 18,
  },
  add: {
    width: 24,
  },
}));

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

function Dashboard() {
  const classes = useStyles();
  const { loading, productionList } = useAdminState().productionList;
  const { profile } = useAdminState().login.user || {};
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("all");
  const [year, setYear] = useState("2024");
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    getProductionList(year === "all" ? "2023,2024" : year, status);
  }, [refetch, year, status]);

  useEffect(() => {
    setData(productionList?.data || []);
  }, [productionList]);

  const columns = useMemo(
    () => [
      { accessorKey: "projectId", header: "ID" },
      { accessorKey: "projectName", header: "Project" },
      { accessorKey: "domain", header: "Domain" },
      { accessorKey: "tpmPo", header: "PM/PO" },
      { accessorKey: "tags", header: "SDT" },
      { accessorKey: "sdm", header: "SDM(S)" },
      {
        accessorKey: "releasedDate",
        header: "Released Date",
        Cell: ({ cell }) => (
          <Box>
            {cell.getValue()
              ? moment(cell.getValue(), "DD/MM/YYYY").format("DD MMM YYYY")
              : "N/A"}
          </Box>
        ),
      },
      { accessorKey: "note", header: "Note" },
      {
        accessorKey: "stage",
        header: "Status",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const color =
            value === "Implementing"
              ? "#276fbf"
              : value === "Cancelled" || value === "On-hold"
              ? "#ff4747"
              : value === "Completed"
              ? "rgb(67, 185, 178)"
              : "#000";

          const IconComponent =
            value === "Implementing"
              ? Cog8ToothIcon
              : value === "Completed"
              ? CheckBadgeIcon
              : value === "Cancelled"
              ? NoSymbolIcon
              : ShieldExclamationIcon;

          return (
            <Box component="span" sx={{ color }}>
              <IconComponent className={classes.svg} />
              {value}
            </Box>
          );
        },
      },
    ],
    [classes.svg]
  );

  const handleExportRows = useCallback((rows) => {
    const rowData = rows.map((row) => row.original);
    const transformedData = rowData.map(
      ({ projectYear, effort, country, ...rest }) => ({
        ...rest,
        projectYear: projectYear?.join(", ") || "",
        country: country?.join(", ") || "",
        effort:
          effort?.map((item) => `${item.type}:${item.amount}`).join(", ") || "",
      })
    );
    const csv = generateCsv(csvConfig)(transformedData);
    download(csvConfig)(csv);
  }, []);

  const handleExportData = useCallback(() => {
    const transformedData = data.map(
      ({ projectYear, effort, country, ...rest }) => ({
        ...rest,
        projectYear: projectYear?.join(", ") || "",
        country: country?.join(", ") || "",
        effort:
          effort?.map((item) => `${item.type}:${item.amount}`).join(", ") || "",
      })
    );
    const csv = generateCsv(csvConfig)(transformedData);
    download(csvConfig)(csv);
  }, [data]);

  const onClickRow = useCallback((e, id) => {
    navigate(`/project-details?id=${id}`);
  }, []);

  const onCloseProject = useCallback(() => setOpen(false), []);

  const handleStatus = useCallback((status) => setStatus(status), []);

  const onClickReset = useCallback(() => {
    setStatus("all");
    setData(productionList?.data);
  }, [productionList]);

  const handleYear = useCallback((year) => setYear(year), []);

  const renderTopToolbar = useCallback(
    ({ table }) => (
      <Box
        sx={{ display: "flex", gap: "16px", padding: "8px", flexWrap: "wrap" }}
      >
        <div className="flex">
          <FormControl fullWidth style={{ marginRight: 15 }}>
            <InputLabel className="select-label" id="year-filter">
              Year
            </InputLabel>
            <Select
              labelId="year-filter"
              value={year}
              label="Year"
              size="small"
              onChange={(e) => handleYear(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="2023">2023</MenuItem>
              <MenuItem value="2024">2024</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel className="select-label" id="status-filter">
              Status
            </InputLabel>
            <Select
              labelId="status-filter"
              value={status}
              label="Status"
              size="small"
              onChange={(e) => handleStatus(e.target.value)}
            >
              <MenuItem value="all">All Projects</MenuItem>
              {PROJECT_STATUS.map((status, index) => (
                <MenuItem key={index} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button onClick={onClickReset} className="reset">
            Reset
          </Button>
        </div>

        <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
          Export All Data
        </Button>

        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
    [
      year,
      status,
      handleYear,
      handleStatus,
      onClickReset,
      handleExportData,
      handleExportRows,
    ]
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableEditing: false,
    initialState: { pagination: { pageSize: 25, pageIndex: 0 } },
    renderTopToolbarCustomActions: renderTopToolbar,
    renderEditRowDialogContent: ({ row, table }) => (
      <AddEditProject
        setOpen={setOpen}
        action="edit"
        title=""
        id={table.getRow(row.id).original.projectId}
        detail={table.getRow(row.id).original}
        refetch={refetch}
        setRefetch={setRefetch}
        projectList
        closeEdit={table.setEditingRow}
      />
    ),
    muiTableBodyRowProps: ({ row, table }) => ({
      onClick: (e) => onClickRow(e, table.getRow(row.id).original.projectId),
      sx: { cursor: "pointer" },
    }),
  });

  if (loading) return <Loading />;

  if (!productionList?.data?.length) {
    return (
      <Container component="main" maxWidth="sm">
        <NoData link="/projectList" setStatus={setStatus} />
      </Container>
    );
  }

  return (
    <div className="dashboard">
      <div className="project-list-container">
        {(profile?.email === "parama.paramagul@devglobal.lotuss.org" ||
          profile?.email === "parama.paramagul@lotuss.com") && (
          <Fab
            color="primary"
            aria-label="add"
            className="add-project"
            onClick={() => setOpen(true)}
          >
            <PlusIcon className={classes.add} />
          </Fab>
        )}

        <div className="page-header">
          <h2>Project List - Local Dev</h2>
          <div className="flex">
            <Breadcrumb page="Project List" link="projectList" />
          </div>
        </div>

        <Paper className="container" elevation={0}>
          <div className="project-table-wrapper">
            <MaterialReactTable table={table} />
          </div>
        </Paper>
      </div>

      <Dialog maxWidth="lg" onClose={onCloseProject} open={open}>
        <AddEditProject
          setOpen={setOpen}
          action="add"
          refetch={refetch}
          setRefetch={setRefetch}
        />
      </Dialog>
    </div>
  );
}

export default Dashboard;
